<template>
  <div class="train_detail_page">
    <div class="video_block">
      <aliVideo :source="source" :videoid="this.$route.query.normalUrl"
   width="7.14rem" isFree ="true"></aliVideo>
    </div>
    <div class="class_name">{{ $route.query.videoname }}</div>
    <div class="content">
      <div class="top">
        <div class="num">{{ list.length }}条评论</div>
        <div class="btns">
          <div
            class="btn_left tab"
            :class="{ active: isNew }"
            @click="tabNew(false)"
          >
            最新
          </div>
          <div
            class="btn_right tab"
            :class="{ active: !isNew }"
            @click="tabNew(true)"
          >
            最早
          </div>
        </div>
      </div>
      <div class="list" v-for="(item, index) in list" :key="index">
        <div class="item">
          <div class="pic"></div>
          <div class="block">
            <div class="phone">{{ item.username }}</div>
            <div class="desc">{{ item.content }}</div>
            <div class="time">
              <div class="left">{{ dateStr(item.updatetime) }}</div>
              <div class="center tab" @click="isopen(item)">
                <span class="ds-icon share1"></span>回复
              </div>
              <div class="right tab" @click="zan(item)">
                <span
                  class="ds-icon"
                  :class="{ praise1: !item.isRead, praise2: item.isRead }"
                ></span
                >赞（{{ item.likes }}）
              </div>
            </div>
            <div class="replay" v-show="item.isReplay">
              <el-input
                style="margin: 0.2rem 0"
                type="textarea"
                :rows="2"
                placeholder="请输入内容"
                v-model="textarea"
              >
              </el-input>
              <div class="replayBtn tab" @click="replaySubmit(item)">回复</div>
            </div>
            <div v-if="item.commentPost && item.commentPost.length">
              <div
                class="list_item"
                v-for="(val, ind) in item.commentPost"
                :key="ind"
              >
                <div class="item">
                  <div class="pic"></div>
                  <div class="block">
                    <div class="phone">{{ val.username }}</div>
                    <div class="desc">{{ val.content }}</div>
                    <div class="time">
                      <div class="left">{{ dateStr(item.updatetime) }}</div>
                      <div class="right">
                        <span
                          class="ds-icon"
                          :class="{
                            praise1: !item.isRead,
                            praise2: item.isRead,
                          }"
                        ></span
                        >赞（{{ item.isRead }}）
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <comPagination :params="params" @changePage="changePage"></comPagination>
    </div>
  </div>
</template>

<script>
import aliVideo from '@/components/video'
import comPagination from '@/components/admin/pagination.vue'
export default {
  name: 'trainDetail',
  components: {
    aliVideo,
    comPagination,
  },
  data() {
    return {
      option: {
        height: '7.14rem',
      },
      list: [{ likes: 0 }],
      isNew: true,
      params: {
        pageno: 0,
        pagesize: 10,
        total: 0,
      },
      textarea: '',
      source: '',
    }
  },
  mounted() {
    // 查询公益园状态







    this.api.unit
      .getPlayInfo({ videoId: this.$route.query.normalUrl, definition: '' })
      .then((res) => {
        console.log(res);
        this.source = res.data.playInfoList[0].playURL
      })
    this.getList()
  },
  methods: {
    getList() {
      let data = {
        articleId: this.$route.query.id,
        pid: 0,
        sort: this.isNew ? 'asc' : 'desc',
        pageno: this.params.pageno,
        pagesize: this.params.pagesize,
      }
      this.api.train.findCommentPost(data).then((res) => {
        this.list = res.data.map((item) => {
          return {
            ...item,
            isReplay: false,
          }
        })
      })
    },
    changePage(val) {
      this.params.pageno = val
      this.getList()
    },
    tabNew(val) {
      this.isNew = val
      this.getList()
    },
    isopen(item) {
      this.list.forEach((val) => {
        if (item.id == val.id) {
          this.textarea = ''
          val.isReplay = !item.isReplay
        } else {
          val.isReplay = false
        }
      })
    },
    // 回复提交
    replaySubmit(item) {
      let data = {
        userId: item.userId,
        articleId: item.articleId,
        pid: item.pid,
        siteId: item.siteId,
        content: this.textarea,
      }
      this.api.train.commentPost(data).then((res) => {
        if (res.flag) {
          this.$notice({
            type: 'success',
            message: res.message
          }).isShow()
          // this.list.forEach((val) => {
          //     val.isReplay = false;
          // });
          this.getList()
        } else {
                          this.$notice({
                  message: res.message
                }).isShow()
        }
      })
    },
    zan(item) {
      this.api.train.addZan({ id: item.id }).then((res) => {
        if (res.flag) {
          this.$notice({
            type: 'success',
            message: res.message
          }).isShow()
          this.getList()
        } else {
                          this.$notice({
                  message: res.message
                }).isShow()
        }
      })
    },
    /**
     * 毫秒转换友好的显示格式
     * 输出格式：21小时前
     * @param  {[type]} time [description]
     * @return {[type]}      [description]
     */

    dateStr(date) {
      //获取js 时间戳
      var time = new Date().getTime()
      //去掉 js 时间戳后三位，与php 时间戳保持一致
      time = parseInt((time - date * 1000) / 1000)

      //存储转换值
      var s
      if (time < 60 * 10) {
        //十分钟内
        return '刚刚'
      } else if (time < 60 * 60 && time >= 60 * 10) {
        //超过十分钟少于1小时
        s = Math.floor(time / 60)
        return s + '分钟前'
      } else if (time < 60 * 60 * 24 && time >= 60 * 60) {
        //超过1小时少于24小时
        s = Math.floor(time / 60 / 60)
        return s + '小时前'
      } else if (time >= 60 * 60 * 24) {
        //超过1天少于3天内
        s = Math.floor(time / 60 / 60 / 24)
        return s + '天前'
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.train_detail_page {
  width: 100%;
  .video_block {
    height: 714px;
    background: #228ded;
    padding: 0 315px;
    margin-bottom: 70px;
    margin-top: 40px;
    box-sizing: border-box;
    width: 100%;
  }
  .class_name {
    text-align: center;
    margin-bottom: 20px;
    font-weight: bold;
    font-size: 30px;
  }
  .content {
    width: 1200px;
    margin: 0 auto;
    box-sizing: border-box;
    @include flex(column, flex-start, flex-start);
    .top {
      width: 100%;
      @include flex(row, space-between, center);
      border-bottom: 1px solid rgba(0, 0, 0, 0.13);
      padding-bottom: 10px;
      .num {
        color: rgb(240, 159, 31);
        font-size: 18px;
      }
      .btns {
        @include flex(row, flex-end, center);
        div {
          padding: 0 4px;
          margin: 0 2px;
          width: 58px;
          height: 28px;
          text-align: center;
          background: rgb(253, 189, 5);
          line-height: 28px;
          border-radius: 14px;
          font-size: 18px;
          color: rgba(255, 255, 255, 0.5);
        }
        .active {
          color: rgba(255, 255, 255, 1) !important;
        }
      }
    }
    .list,
    .list_item {
      width: 100%;
      .item {
        @include flex(row, flex-start, flex-start);
        padding: 15px;
        border-top: 1px solid rgba(0, 0, 0, 0.13);
        .pic {
          width: 48px;
          height: 48px;
          max-width: none;
          border-radius: 50px;
          background: pink;
        }
        .block {
          flex: 1;
          text-align: left;
          padding-left: 20px;
          .phone {
            color: #777777;
          }
          .desc {
            font-size: 13px;
            margin: 5px 0;
            word-wrap: break-word;
          }
          .time {
            font-size: 12px;
            color: #999999;
            @include flex(row, flex-start, center);
            .left {
              margin-right: 8px;
            }
            .center,
            .right {
              margin: 0 6px 0 0;
              padding: 0 6px 0 0;
            }
          }
          .ds-icon {
            vertical-align: middle;
            display: inline-block;
            overflow: hidden;
            background: transparent url('../../assets/img/train/sprites.png')
              no-repeat;
          }
          .ds-icon-reply {
            width: 18px;
            height: 13px;
            background-position: 0 -105px;
          }
        }
      }
    }
    .list_item {
      margin-top: 10px;
      border-top: 1px solid rgba(0, 0, 0, 0.13);
    }
    .list_item .item:first-child {
      border: 0;
      margin-bottom: 0;
    }
    .praise1 {
      width: 0.18rem;
      height: 0.13rem;
      background-position: 0 -120px !important; /*no*/
    }
    .praise2 {
      width: 0.18rem;
      height: 0.13rem;
      background-position: 0 -132px !important; /*no*/
    }
    .share1 {
      width: 0.18rem;
      height: 0.13rem;
      background-position: 0 -107px !important; /*no*/
    }
    .replay::after {
      content: '';
      clear: both;
      display: block;
    }
    .replayBtn {
      background: rgb(253, 189, 5);
      color: #fff;
      width: 58px;
      height: 28px;
      border: 0;
      font-size: 15px;
      border-radius: 12px;
      text-align: center;
      line-height: 28px;
      float: right;
    }
  }
}
</style>
